<template>
    <v-container>
        <Header
            :registryStatus="getPriceListStatuses"
            :recordIndex="getPriceListDetails.index"
            :registryTitle="getPriceListRegistry.name"
            :registryUrl="'price-list'"
            :actualStatus="getPriceListDetails.status"
            :avatarData="getPriceListDetails.createdBy"
            @status-changed="changeStatus"
        ></Header>
        <Main :items="items" class="p-relative">
            <v-tab-item
                v-for="item in items"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
            <div slot="information">
                <Information />
                <Modal
                    :title="$t('price-list:edition')"
                    :open="open"
                    :height="'640'"
                    v-on:close="closeModal()"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        @closeModal="open = false"
                        :action="'edit'"
                        slot="buttons"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--details">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                dark
                                v-on="on"
                                v-if="edit()"
                                small
                                @click="editPriceList()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('price-list:edit') }}</span>
                    </v-tooltip>
                </div>
            </div>
        </Main>
    </v-container>
</template>

<script>
import store from './../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Information from '../../../components/Registries/Price-list/Details/Information.vue'
import AEContent from './../../../components/Registries/Price-list/Modal/Content'
import Buttons from './../../../components/Registries/Price-list/Modal/Buttons'

export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        Information,
        AEContent,
        Buttons,
    },
    data() {
        return {
            open: false,
            items: [],
        }
    },
    computed: {
        ...mapGetters([
            'getPriceListStatuses',
            'getPriceListRegistry',
            'getPriceListDetails',
        ]),
    },
    methods: {
        ...mapMutations([
            'setPriceListDetails',
            'setPriceListStatuses',
            'setPriceListRegistry',
            'setPriceListModal',
            'clearPriceListErrors',
            'clearRepresentativesInput',
            'clearPriceListModal'
        ]),
        ...mapActions(['fetchPriceList', 'updatePriceListStatus']),
        changeStatus(status) {
            this.updatePriceListStatus({ status, id: this.getPriceListDetails._id })
        },
        edit() {
            return this.checkPermissions('UPDATE')
        },
        async editPriceList() {
            this.open = true
            this.clearPriceListErrors()
            this.setPriceListModal()
        },
        closeModal() {
            this.open = false
            this.clearPriceListErrors()
            this.clearRepresentativesInput()
            this.clearPriceListModal()
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchPriceList', { id: to.params.id, next })
    },
}
</script>
