<template>
    <v-container>
        <v-row class="details__card">
            <v-col cols="3" xl="3" lg="3" md="5" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12" class="d-flex flex-column">
                        <p
                            class="font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"
                        >
                            {{ $get(getPriceListDetails,"contractor.name",$t('global:empty')) }}
                        </p>
                        <span
                            ><b>{{ `${$t('price-list:title')}` }}:</b>
                            {{ $get(getPriceListDetails, 'title', '') }}
                        </span>
                        <span
                            ><b>{{ `${$t('price-list:lastModification')}` }}:</b>
                            {{
                                getPriceListDetails.updatedAt
                                    ? $moment(getPriceListDetails.updatedAt).format(
                                          `DD/MM/YYYY`
                                      )
                                    : $t('global:empty')
                            }}</span
                        >
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" xl="6" lg="8" md="12" sm="12" class="pa-5">
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="$get(getPriceListDetails, 'title', '')"
                            :label="$t('price-list:title')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="
                                $get(getPriceListDetails, 'contractor.name', '')
                            "
                            :label="$t('price-list:contractor')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-text-field
                            readonly
                            :value="findRepresentative(getPriceListDetails)"
                            :label="$t('price-list:representative')"
                        ></v-text-field>
                    </v-col>
                </v-row>              
                <v-row>
                     <v-col cols="6"
                        ><v-text-field style="width:60%"
                            readonly
                            :value="
                                getPriceListDetails.createdAt
                                    ? $moment(
                                          getPriceListDetails.createdAt
                                      ).format('DD-MM-YYYY HH:mm')
                                    : $t('global:empty')
                            "
                            :label="$t('price-list:createdAt')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6"
                        ><v-text-field
                            style="width:60%; float:right"
                            readonly
                            :value="
                                getPriceListDetails.updatedAt
                                    ? $moment(
                                          getPriceListDetails.updatedAt
                                      ).format('DD-MM-YYYY HH:mm')
                                    : $t('global:empty')
                            "
                            :label="$t('price-list:lastModification')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12"
                        ><v-textarea
                            readonly
                            :value="$get(getPriceListDetails, 'description', '')"
                            :label="$t('price-list:description')"
                            no-resize
                            rows="1"
                            auto-grow
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    computed: {
        ...mapGetters(['getPriceListDetails']),
    },
    methods: {
        findRepresentative(item) {
            const id = item.representative
            if (id) {
                const representative = item.contractor?.representatives?.find(
                    e => e._id === id
                )

                return `${this.$get(
                    representative,
                    'name',
                    'Brak'
                )} ${this.$get(representative, 'lastname', '')} ${
                    representative && representative.email ? '•' : ''
                } ${this.$get(representative, `email`, '')} ${
                    representative && representative.phoneNumber ? '•' : ''
                } ${this.$get(representative, 'phoneNumber', '')}`
            }
            return ''
        },
    },
}
</script>
