var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"details__card"},[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"3","xl":"3","lg":"3","md":"5","sm":"12"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('p',{staticClass:"font-weight-bold d-flex flex-column headline pa-0 ma-0 mb-5"},[_vm._v(" "+_vm._s(_vm.$get(_vm.getPriceListDetails,"contractor.name",_vm.$t('global:empty')))+" ")]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('price-list:title')}`)+":")]),_vm._v(" "+_vm._s(_vm.$get(_vm.getPriceListDetails, 'title', ''))+" ")]),_c('span',[_c('b',[_vm._v(_vm._s(`${_vm.$t('price-list:lastModification')}`)+":")]),_vm._v(" "+_vm._s(_vm.getPriceListDetails.updatedAt ? _vm.$moment(_vm.getPriceListDetails.updatedAt).format( `DD/MM/YYYY` ) : _vm.$t('global:empty')))])])],1)],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6","xl":"6","lg":"8","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.$get(_vm.getPriceListDetails, 'title', ''),"label":_vm.$t('price-list:title')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.$get(_vm.getPriceListDetails, 'contractor.name', ''),"label":_vm.$t('price-list:contractor')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"readonly":"","value":_vm.findRepresentative(_vm.getPriceListDetails),"label":_vm.$t('price-list:representative')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticStyle:{"width":"60%"},attrs:{"readonly":"","value":_vm.getPriceListDetails.createdAt
                                ? _vm.$moment(
                                      _vm.getPriceListDetails.createdAt
                                  ).format('DD-MM-YYYY HH:mm')
                                : _vm.$t('global:empty'),"label":_vm.$t('price-list:createdAt')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticStyle:{"width":"60%","float":"right"},attrs:{"readonly":"","value":_vm.getPriceListDetails.updatedAt
                                ? _vm.$moment(
                                      _vm.getPriceListDetails.updatedAt
                                  ).format('DD-MM-YYYY HH:mm')
                                : _vm.$t('global:empty'),"label":_vm.$t('price-list:lastModification')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"readonly":"","value":_vm.$get(_vm.getPriceListDetails, 'description', ''),"label":_vm.$t('price-list:description'),"no-resize":"","rows":"1","auto-grow":""}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }